import { Icon, notification } from 'antd'
import Search from 'antd/lib/input/Search'
import { Block } from 'components'
import BillingBlock from 'components/BillingBlock'
import React, { useContext, useState } from 'react'
import { UserDetailContext } from 'store'

//component to show in setting Modal
export const Settings = () => {
  const { user, postUserUpdate } = useContext(UserDetailContext)
  const [introLink, setIntroLink] = useState(user?.introLink)
  const [linkedInUrl, setLinkedInUrl] = useState(user?.linkedinProfileUrl)

  //function will be called on search
  const onSearch = (value, type) => {
    user.linkedinProfileUrl = value
    postUserUpdate({
      id: user.id,
      type,
      url: value,
    })
    notification.success({
      message: 'User Updated',
    })
  }

  // handleLinkChange functiona will be invoked when link is changed
  const handleLinkChange = (e, type) => {
    if (type === 'intro_link') {
      let link = e.target.value.includes('meetingmachine.co/peerLink/')
      if (link) {
        setIntroLink(e?.target?.value)
      } else {
        setIntroLink('meetingmachine.co/peerLink/')
      }
    } else if (type === 'url') {
      let link = e.target.value.includes('linkedin.com/in/')
      if (link) {
        setLinkedInUrl(e?.target?.value)
      } else {
        setLinkedInUrl('linkedin.com/in/')
      }
    }
  }

  return (
    <>
      {' '}
      <div className="ten-px-margin-modal">
        <div className="input-tab">
          <Block
            blockName="PeerLink"
            tooltipPlacement="right"
            tooltipText="Share and apply your PeerLink to your social accounts. You can adjust your vanity name here."
          >
            <label>
              <Search
                placeholder="IntroLink Url"
                enterButton="Update"
                prefix={<Icon type="edit" />}
                defaultValue={user?.introLink}
                onChange={e => handleLinkChange(e, 'intro_link')}
                value={introLink}
                size="large"
                onSearch={value => onSearch(value, 'intro_link')}
              />
            </label>
          </Block>
        </div>
        <div className="input-tab">
          <Block
            blockName="LinkedIn Profile URL"
            tooltipPlacement="right"
            tooltipText="Please ensure your LinkedIn Profile vanity name matches correctly."
          >
            <label>
              <Search
                placeholder="LinkedIn Profile Url"
                enterButton="Update"
                prefix={<Icon type="edit" />}
                defaultValue={user?.linkedinProfileUrl}
                value={linkedInUrl}
                onChange={e => handleLinkChange(e, 'url')}
                size="large"
                onSearch={value => onSearch(value, 'url')}
              />
            </label>
          </Block>
        </div>
      </div>
      {/* <LinkedBlock /> */}
      <BillingBlock />
    </>
  )
}
