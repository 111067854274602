import React, { useState, useContext, useEffect } from 'react'
import {
  Button,
  Select,
  Modal,
  notification,
  message,
  Form,
  Table,
  Tooltip,
} from 'antd'
import { observer } from 'mobx-react-lite'
import Clipboard from 'react-clipboard.js'
import '../CreateNewGroup/CreateNewGroup.scss'
import { Block, CreateNewGroup, ShareGroup } from 'components'
import {
  InterestGroupStoreContext,
  JoinGroupStoreContext,
  UserDetailContext,
  MemberShowcase,
  GroupLinkContext,
} from 'store'
import { Link } from 'react-router-dom'
import { moreInfoText, isProd } from 'config/utils'
import { UserPreference } from './UserPreference'
import './InterestGroupSearchBlock.scss'

const Option = Select.Option

const InterestGroupSearchBlock = ({ form, history, liveUsers }) => {
  const [joinGroupLoading, setJoinGroupLoading] = useState(false)
  const [showActionModal, setShowActionModal] = useState(false)
  const [showInfoModal, setShowInfoModal] = useState(false)
  const [showNewGroupModal, setShowNewGroupModal] = useState(false)
  const [showPreferencesModal, setShowPreferencesModal] = useState(false)
  const [premiumUserWaringModel, setPremiumUserWaringModel] = useState(false)
  const [showShareGroup, setShowShareGroup] = useState(false)
  const [clickedGroup, setClickedGroup] = useState({})
  const [newGroup, setNewGroup] = useState({})
  // const [, setOnlyShare] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { groups, interestGroupSearch } = useContext(InterestGroupStoreContext)
  const { joinGroup } = useContext(JoinGroupStoreContext)
  const { linkedGroups, getAllLinkedGroups, loadingAdminGroups } = useContext(
    GroupLinkContext
  )
  const {
    user,
    modifyGroupDetails,
    getUserGroups,
    userGroups,
    getUserById,
    getAllIntents,
  } = useContext(UserDetailContext)
  const { getPublicSignedUrl } = useContext(MemberShowcase)

  useEffect(() => {
    ;(async () => {
      try {
        const joinSignupGroup = localStorage.getItem('user-group-signup')
        // const introProfile = JSON.parse(localStorage.getItem('introProfile'))
        if (!joinSignupGroup) {
          return localStorage.removeItem('user-group-signup')
        }
        const res = await joinGroup(joinSignupGroup, user.id)
        setJoinGroupLoading(false)
        if (res === 'User already in group') {
          notification.warning({
            message: 'Already Joined',
          })
        } else {
          notification.success({
            message: 'Joined',
          })
          modifyGroupDetails(res)
        }
        // introProfile &&
        //   user.defaultCalendar === '' &&
        //   notification.info({
        //     message: 'Pro Tip!',
        //     description: `${introProfile?.first_name} ${introProfile?.last_name} receives 100s of intro requests monthly, link your calendar to have best chance to meet`,
        //   })
      } catch (error) {
        console.log(error)
      } finally {
        const itemsToRemove = ['introProfile', 'user-group-signup']
        return itemsToRemove.forEach(key => localStorage.removeItem(key))
      }
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      try {
        await getAllIntents({})
      } catch (e) {
        console.log(e)
      }
    })()
  }, [])

  const searchInterestGroup = async value => {
    try {
      await interestGroupSearch(value)
    } catch (e) {
      const { statuscode, message } = e.response.data
      if (statuscode === 400) {
        notification.error({
          message,
        })
        localStorage.clear()
        setTimeout(() => {
          window.location.href = isProd ? 'https://meetingmachine.me' : '/'
        }, 500)
      } else if (statuscode === 500) {
        notification.error({
          message,
        })
      }
    }
  }

  const handleClickedGroup = async (option, openInfoModal = false) => {
    let publicUrl = null
    let customEmail = null
    if (typeof option === 'string') {
      const newOption = JSON.parse(option)
      if (newOption && newOption.group_image) {
        const n = newOption.group_image.lastIndexOf('/')
        const result = newOption.group_image.substring(n + 1)
        publicUrl = await getPublicSignedUrl(result, newOption.group_id)
      }
      if (newGroup && newOption.custom_email) {
        customEmail = newOption.custom_email
      }
      let data = await getUserById({ id: `${newOption.adminId}` })
      await getAllLinkedGroups({
        admin_id: newOption.adminId,
        group_id: newOption.id,
      })
      setClickedGroup(newOption)
      setNewGroup({
        ...newOption,
        name: `${data.firstName} ${data.lastName}`,
        email: data?.authDetails[0]?.email || customEmail || `${data?.email}`,
        profileUrl: data.linkedinProfileUrl || null,
        group_image: publicUrl,
      })
    } else {
      setClickedGroup(option)
      setNewGroup(option)
    }
    setShowNewGroupModal(false)
    setShowInfoModal(true)
  }

  const handleLinkedGroupClick = async (group, openInfoModal = false) => {
    setIsLoading(true)
    try {
      await getAllLinkedGroups({
        admin_id: group.admin_id,
        group_id: group.id,
      })
      let publicUrl = null
      let customEmail = null
      if (group && group.group_image) {
        const n = group.group_image.lastIndexOf('/')
        const result = group.group_image.substring(n + 1)
        publicUrl = await getPublicSignedUrl(result, group.id)
      }
      if (newGroup && group.custom_email) {
        customEmail = group.custom_email
      }
      let data = await getUserById({ id: `${group.admin_id}` })
      setNewGroup({
        ...group,
        adminId: group.admin_id,
        creatorId: group.id,
        name: `${data.firstName} ${data.lastName}`,
        email: data?.authDetails[0]?.email || customEmail || `${data?.email}`,
        profileUrl: data.linkedinProfileUrl || null,
        group_image: publicUrl,
      })
    } catch (error) {
      console.log(error)
    } finally {
      setShowInfoModal(true)
      setIsLoading(false)
    }
  }

  const handleNewGroup = (group, openInfoModal = false) => {
    setNewGroup(group)
    if (openInfoModal) {
      setShowNewGroupModal(false)
    } else {
      setShowActionModal(true)
    }
  }

  const handleGetInfoGroup = () => {
    setShowActionModal(false)
    setShowInfoModal(false)
  }

  const handleJoinGroup = async () => {
    setJoinGroupLoading(true)
    try {
      const res = await joinGroup(newGroup.id, user.id)
      await getUserGroups(user.id)
      setJoinGroupLoading(false)
      if (res === 'User already in group') {
        notification.warning({
          message: 'Already Joined',
        })
      } else {
        notification.success({
          message: 'Joined',
        })
        modifyGroupDetails(res)
      }

      setClickedGroup({})
      setShowActionModal(false)
      setShowInfoModal(false)
    } catch (e) {
      setJoinGroupLoading(false)

      const { statuscode, message } = e.response.data

      if (statuscode === 400 || statuscode === 500) {
        notification.error({
          message,
        })
        localStorage.clear()
        window.location.href = isProd ? 'https://meetingmachine.me' : '/'
      } else if (statuscode === 404) {
        notification.error({
          message,
        })
        history.push('/dashboard')
      }
    }
  }

  const showModal = async group => {
    try {
      //to call an API when there is no linked group.
      if (
        !linkedGroups?.length ||
        group?.userId !== linkedGroups?.[0]?.userId
      ) {
        try {
          await getAllLinkedGroups({
            admin_id: group?.adminId,
            group_id: group?.id,
          })
        } catch (e) {
          //to show the error
          notification.error({
            message: e?.response?.data?.message || 'Something went wrong',
          })
        }
      }
      const data = await getUserById({ id: group.admin_id })
      let publicUrl = null
      let customEmail = null
      if (group && group.group_image) {
        const n = group.group_image.lastIndexOf('/')
        const result = group.group_image.substring(n + 1)
        publicUrl = await getPublicSignedUrl(result, group.group_id)
      }
      if (group && group.custom_email) {
        customEmail = group.custom_email
      }
      setNewGroup({
        ...group,
        name: `${data.firstName} ${data.lastName}`,
        email: data.authDetails[0].email || customEmail || `${data.email}`,
        profileUrl: data.linkedinProfileUrl || null,
        group_image: publicUrl,
      })
      setShowActionModal(false)
      setShowInfoModal(true)
      // setOnlyShare(true)
    } catch (e) {
      //to show the error
      notification.error({
        message: e?.response?.data?.message || 'Something went wrong',
      })
    }
  }

  return (
    <Block
      blockName="1:1 Meeting Manager"
      tooltipPlacement="right"
      tooltipText={moreInfoText.interestGroup}
    >
      <div className="interest-search-group-container">
        <Tooltip title={liveUsers && `${liveUsers} Professionals are live`}>
          <button className="onDemand-Btn onDemandBtnRow">
            <Link className="" to="/ondemand">
              Enter 1:1 Meeting Room
            </Link>
          </button>
        </Tooltip>
        <Select
          className="professional-group-select"
          showSearch
          placeholder="Join New Peer Group"
          size="large"
          value={
            Object.keys(clickedGroup).length > 0
              ? JSON.stringify(clickedGroup)
              : undefined
          }
          showArrow={false}
          onFocus={searchInterestGroup}
          onSearch={searchInterestGroup}
          onChange={e => handleClickedGroup(e)}
          dropdownRender={menu => (
            <div>
              <div className="fixHeightDropDown">{menu}</div>
              <hr />
              <div
                className="drop-down-create-group-btn"
                onMouseDown={() => {
                  if (
                    !user.unlimitedGroups &&
                    user.maxGroups <= userGroups.length
                  ) {
                    setPremiumUserWaringModel(true)
                  } else {
                    setShowNewGroupModal(true)
                  }
                }}
              >
                <Button icon="plus">Create New Group</Button>
              </div>
            </div>
          )}
        >
          {groups &&
            groups.map(option => (
              <Option value={JSON.stringify(option)} key={option.groupName}>
                {option.groupName}
              </Option>
            ))}
        </Select>
        {user?.groupdetails?.[0]?.group && (
          <div className="default-group-container">
            <b>Your Peer Group:</b>
            <p
              className="default-group-name"
              onClick={() => showModal(user.groupdetails[0].group)}
            >{`#${user.groupdetails[0].group.groupName}`}</p>
            <div className="default-actions">
              <Button
                onClick={async () => {
                  await getAllIntents({})
                  setShowPreferencesModal(true)
                }}
              >
                Preferences
              </Button>
              <ShareGroup
                openModal={() => setShowShareGroup(true)}
                show={showShareGroup}
                groupToShare={user.groupdetails[0].group}
                closeModal={() => setShowShareGroup(false)}
                isIntroLinkSharable={true}
              />
            </div>
          </div>
        )}
      </div>
      <Modal
        title="Would you like to:"
        centered
        visible={showActionModal}
        maskClosable
        footer={null}
        onCancel={() => {
          setShowActionModal(false)
          setNewGroup({})
          setClickedGroup({})
        }}
      >
        <div className="centered">
          <Button loading={joinGroupLoading} onClick={handleJoinGroup}>
            Join Group
          </Button>
          <p>or</p>
          <Button onClick={handleGetInfoGroup}>Get Group Info</Button>
        </div>
      </Modal>

      <Modal // Modal to show group info selected from group-manager
        title={`Group Info: #${newGroup?.groupName}`}
        centered
        visible={showInfoModal}
        maskClosable
        footer={null}
        onCancel={() => {
          setShowInfoModal(false)
          // setOnlyShare(false)
          form.resetFields()
        }}
      >
        {isLoading ? (
          <p> loading...</p>
        ) : (
          <div>
            <div>
              <div className="group__image">
                {newGroup?.group_image && (
                  <img
                    src={newGroup.group_image}
                    alt="avatar"
                    style={{ width: '60%' }}
                  />
                )}
              </div>
              <p className="group-info-fields">
                <b>Group Manager: </b>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    newGroup.profileUrl
                      ? `https://${newGroup.profileUrl}`
                      : null
                  }
                >
                  {newGroup.name}
                </a>
              </p>
              <p className="group-info-fields">
                <b>Email: </b>{' '}
                <a href={`mailto:${newGroup.email}`}>{newGroup.email}</a>
              </p>
              <p>
                <b>Group Name:</b> {newGroup.groupName}
              </p>
              <br />
              <p>
                <b>Group Description:</b> {newGroup.description}
              </p>
              <br />
              {(user?.id === newGroup?.adminId ||
                user?.id === newGroup?.userId) &&
                linkedGroups &&
                linkedGroups.length !== 0 && (
                  <Table
                    loading={loadingAdminGroups}
                    dataSource={linkedGroups || []}
                    columns={[
                      {
                        title: `Other Groups Linked to ${newGroup.groupName}`,
                        align: 'center',
                        dataIndex: 'groupName',
                        key: 'id',
                        render: (text, record) => {
                          return (
                            <Button
                              onClick={() => handleLinkedGroupClick(record)}
                            >
                              {text !== '0' && text}
                            </Button>
                          )
                        },
                      },
                    ]}
                  />
                )}
              <p>
                <b>Group Link: </b>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://${newGroup.groupLink}`}
                >
                  https://{newGroup.groupLink}
                </a>
              </p>
              <br />
            </div>

            <div className="centered">
              <Clipboard
                component={Button}
                data-clipboard-text={`https://${newGroup.groupLink}`}
                onSuccess={() => message.success('Link Copied!')}
                onError={() => message.error('Link cannot be copied.')}
              >
                Copy Link
              </Clipboard>
              {user?.interest?.[0] === newGroup?.groupName && (
                <>
                  <Button
                    onClick={() => {
                      setShowPreferencesModal(true)
                      setShowInfoModal(false)
                      // setOnlyShare(false)
                    }}
                  >
                    Preferences
                  </Button>
                </>
              )}
              <ShareGroup
                openModal={() => setShowShareGroup(true)}
                show={showShareGroup}
                groupToShare={newGroup}
                closeModal={() => setShowShareGroup(false)}
                isIntroLinkSharable={false}
              />
              {user?.interest?.[0] !== newGroup?.groupName && (
                <>
                  <Button loading={joinGroupLoading} onClick={handleJoinGroup}>
                    Join Group
                  </Button>
                </>
              )}
            </div>
          </div>
        )}
      </Modal>

      {/* Group Paywall - user notification */}
      <Modal
        title="Unlock Premium Community Building Features"
        centered
        width={500}
        visible={premiumUserWaringModel}
        onCancel={() => setPremiumUserWaringModel(false)}
        footer={null}
      >
        <>
          <div className="container">
            <p style={{ fontSize: '16px', marginBottom: '10px' }}>
              Schedule a call with our solutions team to discuss best options
              and platform features to harness for your community building
              goals.
            </p>
            <Button>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://calendly.com/meeting-machine/meeting-machine-intros-demo"
              >
                Schedule Call
              </a>
            </Button>
          </div>
        </>
      </Modal>

      <Modal
        title="Create New Group:"
        centered
        visible={showNewGroupModal}
        maskClosable
        footer={null}
        destroyOnClose
        onCancel={() => setShowNewGroupModal(false)}
      >
        <CreateNewGroup handleNewGroup={handleNewGroup} />
      </Modal>

      <UserPreference
        showModal={showPreferencesModal}
        setShowModal={setShowPreferencesModal}
        introGroupID={newGroup?.id}
      />
    </Block>
  )
}
export default Form.create()(observer(InterestGroupSearchBlock))
