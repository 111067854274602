import { get, post, getWithParams } from './index'
import { message } from 'antd'

export const sendEmailsViaCSVService = async ({ type, file, group }) => {
  const formData = new FormData()
  formData.append('csv', file)
  formData.append('type', type)
  formData.append('group', group)
  try {
    const response = await post({
      url: `/user/share-group-via-emails`,
      body: formData,
    })
    if (response && response.data) {
      if (response.data.status) message.success(response.data.message)
      else message.warn(response.data.message)
    }
  } catch (error) {
    console.log(error)
  }
}

export const getUserDetailService = async codeObj => {
  const response = await post({
    url: `/linkedin/get-user-detail`,
    body: codeObj,
  })

  return response.data || {}
}

export const getUserListService = async () => {
  const response = await get({
    url: `/user/list`,
  })
  return response.data || {}
}
export const getIntroIntent = async () => {
  const response = await get({
    url: `/intro-intent`,
  })
  if (response?.data?.data) return response.data.data
  return []
}

export const getAllIntentsService = async (params = {}) => {
  const response = await getWithParams({
    url: `/intent`,
    params,
  })
  if (response?.data?.data) return response.data.data
  return []
}

export const createNewIntent = async (body = {}) => {
  const response = await post({
    url: `/intent/create`,
    body,
  })
  if (response?.data?.data) return response.data.data
  return []
}
export const createNewIntroIntent = async (body = {}) => {
  const response = await post({
    url: `/intro-intent/create/`,
    body,
  })
  if (response?.data?.data) return response.data.data
  return []
}

export const updateUserSkills = async body => {
  const response = await post({
    url: `/skills/update/`,
    body,
  })
  if (response?.data?.data) return response.data.data
  return { skills: [], match_skills: [] }
}
export const updateIntroIntents = async (params = {}) => {
  const response = await getWithParams({
    url: `/dropdown/intro-intent`,
    params,
  })
  if (response?.data?.data) return response.data.data
  return []
}

export const assignGroupToUserService = async data => {
  const response = await post({
    url: `/group/assign-group`,
    body: data,
  })
  return response.data || {}
}

export const postUserDetailUpdate = async (
  id,
  title,
  location,
  company,
  industry,
  type,
  preferredDay,
  preferredTime,
  url,
  unlimitedGroupsValue,
  maxGroupsValue
) => {
  const response = await post({
    url: `/user/update`,
    body: {
      id,
      title,
      location,
      company,
      industry,
      type,
      preferredDay,
      preferredTime,
      url,
      unlimitedGroupsValue,
      maxGroupsValue,
    },
  })
  return response.data || {}
}

export const searchService = async (searchType, searchTerm) => {
  const response = await get({
    url: `/get-${searchType}?searchTerm=${searchTerm}`,
  })

  return response.data || {}
}

export const companySearchService = async value => {
  const response = await fetch(
    `https://discovery.clearbit.com/v1/companies/search?query=name:${value}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_CLEARBIT_SECRET}`,
      },
    }
  )
  let res = await response.json()
  return res.results
}

export const exportUserService = async () => {
  const response = await get({
    url: `/update-users-export-tocsv`,
  })
  return response.data || {}
}

export const getUserGroupsService = async userId => {
  const response = await get({
    url: `/user/get-all-groups/?creator_id=${userId}`,
  })

  return response.data || {}
}

export const introPreferencesService = async (userid, groupid, preference) => {
  const response = await post({
    url: `/group/intro-preferences`,
    body: {
      userid,
      groupid,
      preference,
    },
  })
  return response.data
}

export const shareContactService = async (userId, bool, type) => {
  return await post({
    url: `/user/share-contact`,
    body: {
      preference: !bool,
      userId,
      type,
    },
  })
}

export const getMatcherGroupDetails = async (params = {}) => {
  const response = await getWithParams({
    url: `/match-maker`,
    params,
  })
  if (response) return response
  return []
}

export const createMatchMakerGroupLink = async (body = {}) => {
  const response = await post({
    url: `/match-maker/create`,
    body,
  })
  if (response?.data?.data) return response.data.data
  return []
}

export const getMatcherGroupDetailsById = async (params = {}) => {
  const response = await getWithParams({
    url: `/match-maker/details`,
    params,
  })
  if (response) return response
  return []
}

export const updateMatchMakerGroupDetails = async body => {
  const response = await post({
    url: `/match-maker/update`,
    body,
  })
  if (response?.data?.data) return response.data.data
  return []
}

export const deleteMatchMakerGroupDetails = async (body = {}) => {
  const response = await post({
    url: `/match-maker/delete`,
    body,
  })
  if (response) return response
  return []
}

// export const getOngoingScheduledMeeting = async () => {
//   const response = await get({
//     url: `/user/has-ongoing-schedule`,
//   })
//   return response.data || {}
// }

export const getLiveUsers = async () => {
  const response = await get({
    url: `/user/live`,
  })
  return response.data || {}
}
