import { UserDetailContext } from 'store'
import React, { useState, useContext, useEffect } from 'react'
import { Select, Modal, message, Switch, Checkbox, Button } from 'antd'

export const UserPreference = ({
  showModal: showPreferencesModal,
  setShowModal: setShowPreferencesModal,
  introGroupID,
}) => {
  const {
    getUserDetail,
    user,
    shareContactPreference,
    // postUserUpdate,
    allIntents,
    getAllIntents,
    updateUserSkills,
  } = useContext(UserDetailContext)

  useEffect(() => {
    getAllIntents()
  }, [])

  const [skills, setSkills] = useState({
    skills: user?.skills?.map(intent => intent.id) || [],
    match_skills: user?.match_skills?.map(intent => intent.id) || [],
  })
  // const [showCreateIntentModal, setShowCreateIntentModal] = useState(false)
  // const [editIntent, setEditIntent] = useState(null)
  // const [intentName, setIntentName] = useState('')
  // const [intentScore, setIntentScore] = useState(0)
  // const [intentNameError, setIntentNameError] = useState(false)
  // const [loading, setLoading] = useState(false)
  // const [introIntents, setIntroIntents] = useState(null)
  // const [showLinkedBlockModal, setShowLinkedBlockModal] = useState(false)
  const onPreferenceSwitchChange = (bool, type) => {
    shareContactPreference(user.id, bool, type)
  }

  // const getIntroIntents = async () => {
  //   const allIntroIntent = await getIntroIntent()
  //   setIntroIntents(allIntroIntent)
  // }

  // useEffect(() => {
  //   getIntroIntents()
  //   setEditIntent(null)
  // }, [])

  // const handleSubmit = async () => {
  //   if (intentName === '') {
  //     // setIntentNameError(true)
  //   } else {
  //     const data = {
  //       name: intentName,
  //       weigth: intentScore,
  //       id: editIntent?.id || null,
  //     }
  //     try {
  //       setLoading(true)
  //       await createNewIntroIntent(data)
  //       // setShowCreateIntentModal(false)
  //       setIntentName('')
  //       setIntentScore(0)
  //       // getIntroIntents()
  //       setEditIntent(null)
  //       setLoading(false)
  //       notification.success({
  //         message: editIntent?.id
  //           ? 'Intent updated successfully'
  //           : 'Intent created successfully',
  //       })
  //     } catch (e) {
  //       const { statuscode, message } = e.response.data
  //       if (statuscode !== 200) {
  //         return notification.error({
  //           message,
  //         })
  //       }
  //     } finally {
  //       setLoading(false)
  //     }
  //   }
  // }

  // const userGroupColumn = [
  //   {
  //     title: 'Intent',
  //     align: 'center',
  //     dataIndex: 'name',
  //   },
  //   {
  //     title: 'Score',
  //     align: 'center',
  //     dataIndex: 'weigth',
  //   },
  //   {
  //     title: 'Action',
  //     align: 'center',
  //     key: 'action',
  //     render: (text, record) => (
  //       <div
  //         style={{
  //           display: 'flex',
  //           alignItems: 'center',
  //           justifyContent: 'center',
  //           flexDirection: 'row',
  //         }}
  //       >
  //         <Button
  //           style={{ margin: '2px' }}
  //           onClick={() => {
  //             // setShowCreateIntentModal(true)
  //             setEditIntent(record)
  //             setIntentName(record?.name)
  //             setIntentScore(record?.weigth)
  //           }}
  //         >
  //           Edit
  //         </Button>
  //         <Popconfirm
  //           title="Are you sure？"
  //           okText="Yes"
  //           cancelText="No"
  //           onConfirm={async () => {
  //             try {
  //               const data = {
  //                 type: 'DELETE',
  //                 id: record.id,
  //               }
  //               setLoading(true)
  //               await createNewIntroIntent(data)
  //               // setShowCreateIntentModal(false)
  //               // getIntroIntents()
  //               setLoading(false)
  //               notification.success({ message: 'Intent deleted successfully' })
  //             } finally {
  //               setLoading(false)
  //             }
  //           }}
  //         >
  //           <Button danger loading={loading} style={{ margin: '2px' }}>
  //             Delete
  //           </Button>
  //         </Popconfirm>
  //       </div>
  //     ),
  //   },
  // ]

  // const preferredTime = {
  //   preferredTime: user.preferredTime,
  //   preferredDay: user.preferredDay,
  // }

  return (
    <Modal
      title="Preferences: "
      centered
      visible={showPreferencesModal}
      maskClosable
      width={600}
      footer={null}
      destroyOnClose
      onCancel={async () => {
        await setShowPreferencesModal(false)
        await getUserDetail()
      }}
    >
      {/* <LinkedBlockModel
        showLinkedBlockModal={showLinkedBlockModal}
        setShowLinkedBlockModal={setShowLinkedBlockModal}
      /> */}
      <div className="ten-px-margin">
        <SkillsAndMatchSkills
          allIntents={allIntents}
          user={user}
          setSkills={setSkills}
          skills={skills}
          introGroupID={introGroupID}
          updateUserSkills={updateUserSkills}
          setShowPreferencesModal={setShowPreferencesModal}
          getUserDetail={getUserDetail}
        />

        {/* preffered time selection */}
        {/* <div style={{ textAlign: 'center' }}>
          <h3>Your Default 1:1 availability​</h3>
          <p>
            (<strong>Note</strong>: link your calendar for optimum experience{' '}
            <Button
              style={{ padding: 0, height: 'fit-content' }}
              type="link"
              onClick={() => setShowLinkedBlockModal(true)}
            >
              here
            </Button>
            )
          </p>
          <Select
            centered
            defaultValue={JSON.stringify(preferredTime)}
            style={{ maxWidth: 400, width: '100%' }}
            onChange={async preferredTime => {
              await postUserUpdate({
                type: 'prefferedTiming',
                ...JSON.parse(preferredTime),
              })
              await getUserDetail()
            }}
          >
            <Option
              value={JSON.stringify({
                preferredTime: 20,
                preferredDay: 3,
              })}
            >
              Wednesdays at 8pm UTC
            </Option>
            <Option
              value={JSON.stringify({
                preferredTime: 4,
                preferredDay: 4,
              })}
            >
              Thursdays at 4am UTC
            </Option>
            <Option
              value={JSON.stringify({
                preferredTime: 12,
                preferredDay: 4,
              })}
            >
              Thursdays at 12pm UTC
            </Option>
          </Select>
        </div>
        <br /> */}

        <Switch
          style={{ justifyContent: 'left' }}
          onChange={e => onPreferenceSwitchChange(e, 'INTRO_PREFERENCE')}
          defaultChecked={user.introPreference}
        />
        <label>
          <b> : 1:1s within group only</b>
        </label>
      </div>
      <div className="ten-px-margin">
        <Switch
          className="align-left"
          onChange={e => onPreferenceSwitchChange(e, 'SHARE_PREFERENCE')}
          defaultChecked={!user.shareContact}
        />
        <label>
          <b> : Do not share my contact info with group manager</b>
        </label>
      </div>
      {/* <div className="ten-px-margin">
        <Switch
          className="align-left"
          onChange={e => onPreferenceSwitchChange(e, 'AUTO_ACCEPT')}
          defaultChecked={user.auto_accept}
        />
        <label>
          <b> : Auto accept my calendar 1:1 invites</b>
        </label>
      </div>
      <div className="ten-px-margin">
        <Switch
          className="align-left"
          onChange={e => onPreferenceSwitchChange(e, 'PAUSE_INTROS')}
          defaultChecked={user.pauseIntros}
        />
        <label>
          <b> : Pause 1:1s</b>
        </label>
      </div>
      <div className="ten-px-margin">
        <Switch
          className="align-left"
          onChange={e => onPreferenceSwitchChange(e, 'MONTHLY_INTROS')}
          defaultChecked={user.monthlyIntros}
        />
        <label>
          <b> : Monthly 1:1s Only</b>
        </label>
      </div> */}
      {/* <br />
      <div style={{ display: 'flex' }}>
        <h4 style={{ marginRight: '5px' }}>PeerLink Custom Match Intent</h4>
        <Tooltip
          placement="right"
          title="When professionals request intro to you, ask for their intent. Add in custom intents  and score. You can enter in positive or negative weights –100 thru 100."
        >
          <Icon type="info-circle" className="info-circle" />
        </Tooltip>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          margin: '2px',
        }}
      >
        <Table
          style={{
            height: '180px',
            overflowY: 'scroll',
            overflowX: 'hidden',
            width: '-webkit-fill-available',
            margin: '2px',
          }}
          bordered
          //loading={loading}
          scroll={{ x: 400 }}
          pagination={false}
          rowKey={'id'}
          columns={userGroupColumn}
          dataSource={introIntents}
        />
        <Button
          style={{ margin: '10px' }}
          onClick={() => setShowCreateIntentModal(true)}
        >
          {'Create +'}
        </Button>
        <Modal
          title={editIntent ? 'Edit Intent' : 'Create New Intent'}
          footer={false}
          visible={showCreateIntentModal}
          onCancel={() => {
            setShowCreateIntentModal(false)
            setIntentName('')
            setIntentScore(0)
            setEditIntent(null)
            getIntroIntents()
          }}
        >
          <div style={{ display: 'flex' }}>
            <div style={{ width: '500px' }}>
              <Input
                name="intent_name"
                value={intentName}
                onChange={e => {
                  setIntentName(e.target.value)
                  setIntentNameError(false)
                }}
                placeholder="Enter intent name"
                required={true}
              />
              {intentNameError && (
                <div style={{ color: 'red' }}>Intent name is required</div>
              )}
            </div>
            <InputNumber
              name="intent_score"
              onChange={e => setIntentScore(e)}
              placeholder="Enter intent score"
              min={-100}
              max={100}
              value={intentScore}
              required={true}
              style={{ width: '500px' }}
            />

            <Button
              htmlType="submit"
              style={{ marginTop: '0px' }}
              type="primary"
              className="create-new-group-btn"
              onClick={handleSubmit}
              loading={loading}
            >
              {editIntent ? 'Save' : 'Create'}
            </Button>
          </div>
        </Modal>
      </div> */}
    </Modal>
  )
}
const SkillsAndMatchSkills = ({
  allIntents = [],
  user,
  setSkills,
  skills,
  introGroupID,
  updateUserSkills,
  setShowPreferencesModal,
  getUserDetail,
}) => {
  const [filterIntents, setFilterIntents] = useState(false)
  const [specificIntents, setSpecificIntents] = useState(false)
  const [intents, setIntents] = useState(allIntents)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (intents) {
      if (!intents.filter(value => value?.group_id === introGroupID).length) {
        setSpecificIntents(true)
      } else {
        setSpecificIntents(false)
      }
    }
  }, [intents])

  useEffect(() => {
    if (filterIntents) {
      setIntents(intents.filter(value => value?.group_id === introGroupID))
    } else {
      setIntents(allIntents)
    }
  }, [filterIntents])
  // const currentJoinedGroupId = user?.groupdetails?.[0]?.group?.id || null
  return (
    <>
      <div style={{ margin: '5px' }}>
        My skills, interests & associations:{' '}
        {!specificIntents && (
          <div style={{ float: 'right' }}>
            Show my intro group’s specific intents only :
            <Checkbox
              onChange={e => {
                setFilterIntents(e.target.checked)
              }}
              defaultChecked={filterIntents}
              checked={filterIntents}
            />
          </div>
        )}
        <div style={{ margin: '2px' }}>
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Please select"
            defaultValue={
              user?.skills?.map(
                intent =>
                  intent.name ||
                  allIntents?.map(item => item.id === intent && item.name)
              ) || []
            }
            onChange={(value, options) => {
              if (value?.length > 3)
                return message.warning(
                  'Only up to three intro Tags are allowed.'
                )
              else setSkills({ ...skills, skills: options.map(v => v?.key) })
            }}
          >
            {intents?.map(({ name, id }) => (
              <Select.Option key={id} value={name}>
                <span>{name}</span>
              </Select.Option>
            ))}
          </Select>
        </div>
        <br />
        My match's skills, interests & associations:{' '}
        <div style={{ margin: '2px' }}>
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Please select"
            defaultValue={
              user?.match_skills?.map(
                intent =>
                  intent.name ||
                  allIntents?.map(item => item.id === intent && item.name)
              ) || []
            }
            onChange={(value, options) => {
              if (value?.length > 3)
                return message.warning(
                  'Only up to three intro Tags are allowed.'
                )
              else
                setSkills({ ...skills, match_skills: options.map(v => v?.key) })
            }}
          >
            {intents?.map(({ name, id }) => (
              <Select.Option key={id} value={name}>
                <span>{name}</span>
              </Select.Option>
            ))}
          </Select>
        </div>
        <div style={{ textAlign: 'center', padding: ' 20px' }}>
          <Button
            type="primary"
            loading={loading}
            onClick={async () => {
              setLoading(true)
              await updateUserSkills(skills)
              setShowPreferencesModal(false)
              setLoading(false)
              await getUserDetail()
            }}
          >
            Save Matching Preferences
          </Button>
        </div>
      </div>
    </>
  )
}
