import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { ProfileAvatar, OnboardingUserModal } from 'components'

const ProfileInfo = ({ profile }) => {
  const [showOnboardingUserModal, setShowOnboardingModal] = useState(false)
  const [sendingEmail, setSendingEmail] = useState(false)

  const isTitle = profile?.title?.length > 0
  const isLocation = profile?.location?.[0]?.length > 0
  const isCompany = profile?.company?.length > 0
  const isIndustry = profile?.industry?.[0]?.length > 0

  useEffect(() => {
    if (Object.keys(profile).length > 0) {
      if (isTitle && isLocation && isCompany && isIndustry) {
        setShowOnboardingModal(false)
      } else if (
        !(isTitle && isLocation && isCompany && isIndustry) &&
        !showOnboardingUserModal
      ) {
        setShowOnboardingModal(true)
      }
    }
  }, [])

  const handleCancel = async () => {
    if (sendingEmail) {
      return
    }
    let changeProfile = localStorage.getItem('profileDataChanged')
    if (changeProfile === 'true') {
      setSendingEmail(true)
      setShowOnboardingModal(false)
      // await sendEmailOnIncompleteProfile()
      setSendingEmail(false)
    } else {
      setShowOnboardingModal(false)
    }
  }

  return (
    <div
      className={`profile-container ${
        !(isTitle && isLocation && isCompany && isIndustry)
          ? 'incomplete-profile-container'
          : ''
      }`}
    >
      <div className="profile-top">
        <ProfileAvatar profileUrl={profile.profileUrl} />
        <h1 className="profile-title">
          {`${profile.firstName} ${profile.lastName}`}
        </h1>
      </div>
      <div className="profile-middle">
        {!(isTitle && isLocation && isCompany && isIndustry) ? (
          <div className="onboarding-container">
            <h2>
              Please complete your profile to enhance your professional intros.
            </h2>
          </div>
        ) : (
          <div className="profile-info-container">
            <div className="profile-info-block">
              <div className="profile-info">
                <h4>Job Title:&nbsp;</h4>
                <h2>{profile.title}</h2>
              </div>
              <div className="profile-info">
                <h4>Location:&nbsp;</h4>
                <h2>{profile.location[0]}</h2>
              </div>
            </div>
            <div className="profile-info-block">
              <div className="profile-info">
                <h4>Company:&nbsp;</h4>
                <h2>{profile.company}</h2>
              </div>
              <div className="profile-info">
                <h4>Industry:&nbsp;</h4>
                <h2>{profile.industry[0]}</h2>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="profile-bottom">
        <OnboardingUserModal
          profile={profile}
          show={showOnboardingUserModal}
          openModal={() => setShowOnboardingModal(true)}
          closeModal={handleCancel}
        />
      </div>
    </div>
  )
}

ProfileInfo.propTypes = {
  profileName: PropTypes.string,
  title: PropTypes.string,
  location: PropTypes.string,
  company: PropTypes.string,
  industry: PropTypes.string,
}

ProfileInfo.defaultProps = {
  profileName: '',
  title: '',
  location: '',
  company: '',
  industry: '',
}

export default ProfileInfo
